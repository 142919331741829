import { Box, Container, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";

const ResponsiveContainer = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Box>
        {children}
      </Box>
    );
  }

  return (
    <Container>
      {children}
    </Container>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    sx: { alignItems: 'flex-start' },
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


const ReviewsPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{
      backgroundColor: '#FBF8F5',
      paddingBottom: '75px'
  }}> 
        <iframe
        src="https://vivacious-notes-517271.framer.app/reviews-embedded"
        style={{ width: "100vw", height: "100vh", border: "none" }}
        title="Embedded Site"
        ></iframe>
    </Box>
  )
}

export default ReviewsPage;